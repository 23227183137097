@import "src/assets/styles/globalStyles";

.basic-info{
  width: 100%;
  display: flex;
  justify-content: space-between;

}

.drag-drop {
  background: $lightMain;
  border: 3px dashed $dashed;
  border-radius: 8px;
  height: 284px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 1920px) {
    height: 284px;
  }

  img {
    margin-bottom: 16px;
  }
}

.load-div {
  border-bottom: 1px solid $textLight;
  width: 500px;

  .MuiLinearProgress-barColorPrimary {
    background: $main,
  }
}

.select-text {
  font-size: 16px;
  color: $textPrimary;
  margin-bottom: 16px;
}

.upload {
  font-size: 24px;
  font-weight: 600;
  color: $main !important;
  margin-left: 8px;
}

.upload-err {
  font-size: 16px;
  font-weight: 600;
  color: $themeRed !important;
  margin-left: 8px;
}


.document-select-paper-box{
  border-radius: 6px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10);
  .MuiBox-root {
    width: 100%;
    background: #FFF;
    display: flex;
  }

  .delete-document-btn{
    display: flex;
    align-items: center;
  }

  button{
    width: 100%;
    height: 44px;
    padding: 9px 24px;
    color: #324557;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    background: transparent;
    border: none;
    display: flex;
    align-items: flex-start;

    img{
      width: 24px;
      height: 24px;
      min-width: 24px;
      margin-right: 7px!important;
    }
    p{
      color: #324557;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  button:hover{
    background: #F5F7F9!important;
  }

  .MuiBox-root{
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10);
  }
}
