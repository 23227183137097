$main: #438afe;
$lightMain: #f7f9fc;
$primary: #51566d;
$dashed: #438afe4d;
$textLight: #51566d80;
$textPrimary: #51566d;
$textSecondary: #2a374e;
$primaryGray: #e6ecf3;
$themeRed: #d72525;

#spin {
  display: none;
}

.brd {
  border: 1px solid red;
}

.full-width {
  width: 100%;
}

.full-width-flex-able {
  width: 100%;
  display: flex;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.load-row-wrapper {
  width: 100%;
  display: flex;
  margin-top: 24px;
}

.load-row--column-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.MuiTabs-root {
  min-height: 36px !important;
}

.MuiAppBar-colorPrimary {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}

.my-profile {
  .MuiBackdrop-root {
    background: transparent !important;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset;
  color: #545f7e;
}

.MuiAccordionSummary-root {
  padding: 0 !important;
}

.accordion {
  display: inline-flex !important;
}

.MuiAccordion-root {
  margin-bottom: 40px;
}

.accordion-style {
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
}

.MuiAccordion-root:before {
  display: none;
}

.MuiAccordionDetails-root {
  padding: 0 !important;
}

#muiAccordion {
  .MuiAccordionDetails-root {
    padding: 0 !important;
  }
}

.authorites {
  .MuiAccordionSummary-root {
    height: 48px;
    background: #e6ecf380 0 0 no-repeat padding-box;
    border-radius: 4px;
    margin-bottom: 8px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 0 16px !important;
  }
}

.authorites {
  height: 48px;
  background: #e6ecf380 0 0 no-repeat padding-box;
  border-radius: 4px;
  margin-top: 8px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 16px !important;
}

.authorites-item {
  height: 48px;
  background: #e6ecf380 0 0 no-repeat padding-box;
  border-radius: 4px;
  margin-top: 8px;
  align-items: center;

  .MuiAccordionSummary-root {
    height: 48px;
    background: #e6ecf380 0 0 no-repeat padding-box;
    border-radius: 4px;
    margin-bottom: 8px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 0 16px !important;
  }
}

::-webkit-scrollbar {
  display: none;
}

.filter-menu {
  .MuiList-padding {
    padding: 8px 16px !important;
    max-height: 200px;
    overflow: auto;
  }

  .MuiSvgIcon-root {
    color: #00c851;
  }

  .MuiTypography-body1 {
    font-size: 14px;
    color: #51566d;
  }
}

.button-style {
  .MuiTypography-colorPrimary {
    color: white;
    margin-bottom: 5px;
  }
}

.success {
  .Toastify__progress-bar--default {
    background: #00c851;
  }
}

.error {
  .Toastify__progress-bar--default {
    background: #d72525;
  }
}

.MuiDivider-root {
  background-color: #1c2331 !important;
}

.leftModalDialog {
  position: relative !important;
  //z-index: 10!important;
  display: flex;
  margin-top: -780px;
  justify-content: flex-end;

  .MuiDialog-container {
    .MuiDialogTitle-root {
      padding: 0;
      font: normal normal 600 18px/24px Open Sans;
      letter-spacing: 0px;
      color: #51566d;
      margin-bottom: 24px;
    }
  }

  .MuiDialog-paperWidthSm {
    width: 400px;
    padding: 16px;
    height: 452px;
    right: 32px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #8a8a8a29;
    border-radius: 8px;
  }
}

.paymentAccordion {
  background-color: #e6ecf3 !important;
  padding: 0 16px !important;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }

  .MuiAccordion-root {
    margin-bottom: 16px !important;
  }
}

.paymentAccord {
  .MuiAccordionDetails-root {
    padding: 0px !important;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px !important;
    height: 48px !important;
  }
}

.loadItems {
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px !important;
    height: 48px !important;
  }

  .MuiAccordion-root {
    margin-bottom: 8px !important;
  }
}

.MuiAccordion-rounded {
  box-shadow: none !important;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: none;
  padding: 6px 6px;
  cursor: pointer;
  font: normal normal bold 20px/30px;
  color: #387dff;
  display: flex;
}

.file-preview {
  margin: 0 10px;
}

.MuiStepLabel-labelContainer {
  width: 129px;
}

.fromTo {
  position: absolute;
  -webkit-animation: slideUp 1s forwards;
  animation: slideUp 1s forwards;
  width: 100%;
}

@-webkit-keyframes slideUp {
  from {
    top: 150px;
  }

  to {
    top: 85px;
  }
}

@keyframes slideUp {
  from {
    top: 150px;
  }

  to {
    top: 85px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.items-style {
  display: flex;
  align-items: center;
  background: #e6ecf380 0% 0% no-repeat padding-box;
  border-radius: 8px;
  height: 40px;
  padding: 0 16px;

  p {
    color: #51566d;
    font-size: 16px;
  }

  span {
    color: #51566d;
    font-size: 16px;
    font-weight: bold;
    margin-left: 6px;
  }
}

//Global Styles

.details-title {
  font: normal normal bold 24px/33px Open Sans;
  letter-spacing: 0;
  color: #2a374e;
}

.flex-align-start {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.flex {
  display: flex;
  width: 100%;
}

.only-flex {
  display: flex;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.space-between-start {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-justify-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.white-items-wrapper {
  max-height: 70vh;
  overflow: auto;
  height: auto;
  width: 100%;
  background: #f7f9fc 0% 0% no-repeat padding-box;
  border-radius: 8px;
  margin-left: 24px;
  padding: 16px;
  @media (min-width: 1919px) {
    padding: 24px;
  }
}

.info-table-no-line {
  width: 100%;
  margin-left: 40px;
  @media (min-width: 1919px) {
    margin-left: 48px;
    margin-top: 20px;
  }
}

.submit-button {
  height: 32px;
  width: 94px;
  background: #438afe 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #438afe4d;
  border-radius: 18px;
  color: white;
  font-size: 14px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.do-not-pay-text {
  font-weight: 600;
  font-size: 14px;
  color: $themeRed;
}

.blue-button {
  //width: 141px;
  height: 32px;
  background: #438afe 0% 0% no-repeat padding-box;
  box-shadow: 0 0 6px #438afe4d;
  border-radius: 18px;
  color: white;
  font-size: 14px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-load {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.positive-type {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: $main;
  margin-left: 4px;

  .text-style {
    font-weight: 600;
    font-size: 14px;
    color: $main;
  }
}

.negative-type {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: $themeRed;
  margin-left: 4px;

  .text-style {
    font-weight: 600;
    font-size: 14px;
    color: $themeRed;
  }
}

.request-correction {
  width: 145px;
  height: 32px;
  border: 1px solid #438afe;
  border-radius: 18px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #438afe4d;
  color: #438afe;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  @media (min-width: 1919px) {
    width: 185px;
    font-size: 14px;
  }
}

.text-style {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #51566d;
}

#without_ampm::-webkit-datetime-edit-ampm-field {
  display: none;
}

input[type="time"]::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  margin: -10px;
}

.reinviteRmisWrapper {
  display: flex;
  align-items: center;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #51566d;
  }

  button {
    width: 55px;
    height: 25px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #438afe;
    text-decoration: underline;
    margin-left: 16px;
    border: none;
    background: transparent;
  }
}

.acceptDenyWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  button {
    border: none;
    background: transparent;
    height: 25px;
  }
}

.report {
  width: 100%;
  //max-width: 1590px;
  overflow-x: scroll;

  .report-detail-wrapper {
    //width: 2500px;
  }

  .full-table {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

.report::-webkit-scrollbar {
  display: block !important;
}

.report::-webkit-scrollbar {
  width: 5px !important;
  height: 8px !important;
  background-color: white !important;
  border-radius: 4px;
}

/* Add a thumb */
.report::-webkit-scrollbar-thumb {
  background: #e6ecf3 !important;
  border-radius: 4px;
}

.MuiAutocomplete-popper {
  z-index: 2600 !important;
}

.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: solid !important;
}

// Terms And Conditions Page.
.terms-condition {
  .page-info {
    .title-box {
      margin-bottom: 24px;
      @media (min-width: 768px) {
        margin-bottom: 32px;
      }
      @media (min-width: 1440px) {
        margin-bottom: 24px;
      }

      .privacy-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #2b273c;
      }

      .space {
        margin: 0 5px;
      }
    }

    .privacy-body {
      background: #f5f7f9;
      border-radius: 12px;
      padding: 24px 24px 180px 24px;
      @media (min-width: 768px) {
        padding: 32px;
      }
      @media (min-width: 1440px) {
        padding: 40px;
      }

      .privacy-subtitle {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #757280;
        margin-bottom: 24px;
      }
    }
  }

  .privacy-page {
    background: white;
    padding: 120px 0;

    @media (min-width: 768px) {
      padding: 140px 0;
    }
    @media (min-width: 1440px) {
      padding: 160px 0;
    }
  }

  .MuiButton-contained:hover {
    background: #438afe;
    box-shadow: none;
  }

  .dark-link {
    text-transform: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .blue-btn {
    background: #438afe;
    border-radius: 8px;
    color: white;
    align-items: center;
    padding: 12px 52px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    :hover {
      background: #438afe;
      box-shadow: none;
    }
  }
}

.contact-modal {
  padding: 0 32px;

  @media (max-width: 550px) {
    padding: 0 16px;
    width: 344px;
    max-height: 630px;
    height: 75vh;
    overflow-y: auto;
  }

  .banner-box {
    display: flex;
    flex-direction: column;
  }

  .baner-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 550px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .title-modal {
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      margin-left: 22px;
      @media (max-width: 550px) {
        margin-left: 0;
        margin-top: 24px;
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
      }
    }
  }

  .banmer-text {
    margin-top: 16px;

    .subtitle {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      text-align: start;
    }
  }

  .send-form {
    margin-top: 32px;
  }

  .send-box {
    display: flex;
    gap: 20px;

    .cancel-btn {
      width: 100%;
      background: #f7f9fc;
      border-radius: 8px;
      padding: 12px 95px;
      text-align: center;
      color: #51566d;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-transform: capitalize;
    }

    .send-btn {
      width: 100%;
      background: #438afe;
      border-radius: 8px;
      padding: 12px 95px;
      text-align: center;
      color: #ffffff;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-transform: capitalize;
    }

    @media (max-width: 550px) {
      .cancel-btn,
      .send-btn {
        padding: 12px 44px;
      }
    }
  }

  .form-group {
    width: 100%;
    margin-bottom: 16px;

    .MuiOutlinedInput-notchedOutline {
      border-color: #9C9EA9;
      border-radius: 4px;
    }

    .text-area {
      border-color: #9C9EA9;
      border-radius: 4px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #9C9EA9;
    }
  }
}

// Side Menu.

.side-burger {
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
}

.side-container {
  padding: 16px;
  @media only screen and (min-width: 768px) {
    padding: 16px 40px 64px 40px;
  }

  .list-menu {
    padding-top: 50px;

    & .MuiListItem-root {
      padding: 0;
    }

    .not-hover:hover {
      background-color: inherit;
    }
  }

  .drawer-link-styles {
    text-decoration: none;

    .MuiButton-root:hover {
      background-color: inherit;
      color: rgb(236, 199, 17);
    }
  }

  .logo-img {
    padding: 0 12px;
  }

  .side-link {
    .dark-link {
      font-size: 16px;
      line-height: 24px;
      color: #20313f;
      text-transform: none;
    }

    .blue-link {
      font-size: 16px;
      line-height: 24px;
      color: #438afe;
      text-transform: none;
    }

    & .MuiTypography-root {
      font-family: "Noir Pro";
      font-weight: 600;
    }
  }

  .social-box {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.negative-price {

  p {
    color: #F07379;
  }
}

.positive-price {
  p {
    color: #438AFE;
  }
}

.table-second-item {
  margin-top: 4px;
}

.modal-hyper-link {
  cursor: pointer;
  color: #438AFE;

  .text-style {
    color: #438AFE;
  }
}

.text-style {
  width: 100%;
}

.credit-check-btn {
  border: none;
  border-radius: 32px;
  background: #438AFE;
  box-shadow: 0px 0px 6px 0px rgba(67, 138, 254, 0.30);
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 134px;
  height: 36px;
  margin: 0 24px;
}

.button-transparent {
  background: transparent;
  border: none;
  padding: 0;
}

.settings-icon-title {
  display: flex;
  align-items: center;

  p {
    color: #2A374E;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin-left: 16px;
  }
}

.action-settings-wrapper {
  padding: 0 24px;
  border-radius: 8px;
  background: #E6ECF3;
  height: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;

  .setting-flex {
    display: flex;
    align-items: center;
  }

  .right {
    margin-right: 16px;
  }

  .setting-title {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: capitalize;
    margin: -1px 8px 0;
  }
}

.MuiCheckbox-colorSecondary.Mui-disabled {
  opacity: 0.5
}


.popper-menu {

  .filter-type-text {
    color: #4B5C68 !important;
    font-size: 14px;
    font-weight: 600;
    padding: 16px 24px;
    font-family: "Open Sans", sans-serif;
  }

  .MuiList-padding {
    padding: 0 0 12px 0;
  }

  .MuiFormGroup-row {
    background: transparent;
    width: 100%;
  }

  .MuiFormControlLabel-root {
    margin: 0;
    padding: 0 6px;
    width: 100%;
    color: #4B5C68;
    font-size: 12px;
    font-weight: 400;
    line-height: 23px;
  }
}

.inputs-gap {
  display: flex;
  gap: 16px;
  margin-top: 12px;
  margin-bottom: 5px;
  @media (min-width: 1919px) {
    gap: 25px;
  }
}

.beck-drop {
  height: 100vh;
  width: 100%;
  z-index: 9;
  position: fixed;
  top: 0;
  right: 0;
}

.status-select-paper-box {
  .MuiBox-root {
    width: 150px;
    margin-top: 4px;
    padding: 16px 0;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
  }
}


.reset-btn {
  background: transparent;
  border: none;
  padding: 0;
}

.link-btn {
  background: transparent;
  border: none;
  padding: 0;
  text-decoration: underline;
  color: rgb(81, 86, 109);
}


.note-drawer-modal {
  z-index: 3001 !important;

  & .MuiDrawer-paper {
    max-height: 595px;
    margin: 0 auto;
    //right: 0;
    //bottom: 0;
    top: unset;
    width: 1200px;
    border-radius: 8px;
    border: 1px solid rgba(52, 122, 240, 0.50);

    @media (min-width: 1919px) {
      right: 32px;
      bottom: 24px;
    }
  }
}

.note-title-input {
  color: #4B5C68;
  font-size: 24px;
  font-weight: 700;
  border: none;
  outline: none;
  width: 100%;
  height: 33px;
  margin-bottom: 16px;
  background: transparent;

  &::placeholder {
    color: rgba(75, 92, 104, 0.40) !important;
  }
}


.modal-body-style-no-p {
  height: auto;
  max-height: 90vh;
  overflow: auto;
  width: auto;
}


.uploaded-csv {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  padding: 24px 0 0 0;
  font-size: 16px;
  color: #2A374E;

  button {
    background: transparent;
    border: none;
  }
}

.toast-wrapper {
  display: flex;
  align-items: center;

  img {
    margin-right: 16px;
  }

  p {
    font-size: 16px;
    color: #4B5C68;
    line-height: 21;
  }
}

.MuiTooltip-popper{
  z-index: 99999!important;
}

.big-check-box{
  .MuiSvgIcon-fontSizeSmall{
    font-size: 28px;
  }
}