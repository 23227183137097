
.item-button {
  background: transparent;
  border: none;
  outline: none;
  margin-top: 2px;
  padding: 0;
  height: 18px;
  width: 10px;
  min-width: 10px;
}

.popper-menu {

  .filter-type-text {
    color: #4B5C68!important;
    font-size: 14px;
    font-weight: 600;
    padding: 16px 24px;
    font-family: "Open Sans", sans-serif;
  }

  .MuiList-padding {
    padding: 0 0 12px 0;
  }

  .menu-list-box {
    .MuiFormGroup-row {
      background: transparent;
      width: 100%;

      & :hover {
        background: #EBF2FD;
      }
    }

    .MuiFormControlLabel-root {
      margin: 0;
      padding: 0 14px;
      width: 100%;
      color: #4B5C68;
      font-size: 12px;
      font-weight: 400;
      line-height: 23px;
    }
  }
}