// Login Page Style.

.login-fragment {
  .new-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    @media (max-width: 1279px) {
      display: none;
    }
  }

  //   Left Side

  .left-side {
    width: 50%;
    background-image: url("../../assets/images/sign-visual.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    height: 100vh;
    .site-log {
      margin-top: 16px;
      font-size: 20px;
      line-height: 28px;
      font-weight: 700;
      color: #ffffff;
      margin-left: 100px;
    }
    .box-title {
      display: flex;
      align-items: center;
      height: 70%;
      margin-left: 100px;
      .loginPageTitle {
        border-left: 12px solid #ecc711;
        height: 177px;
        p {
          font-size: 40px;
          line-height: 64px;
          font-weight: 800;
          letter-spacing: 2.4px;
          color: #ffffff;
          text-transform: uppercase;
          margin-left: 12px;
          @media (min-width: 1920px) {
            margin-left: 24px;
          }
        }
      }
    }
    @media (max-width: 1279px) {
      display: none;
    }
  }

  //   Right Side

  .right-side {
    width: 50%;
    background: #ececec;
    height: 100vh;
    min-height: 100vh;

    .site-phone {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
      .login-phone {
        margin-right: 100px;
        a {
          text-decoration: none;
          display: flex;
          align-items: center;
        }
        img {
          margin-right: 8px;
        }
        span {
          font-size: 16px;
          line-height: 22px;
          color: #2a374e;
        }
      }
    }
    .form-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      min-height: 100vh;
    }
    @media (max-width: 1279px) {
      display: none;
    }
  }

  .smal-container {
    @media (min-width: 1280px) {
      display: none;
    }
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: url("../../assets/images/visual-1280.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // background-size: cover;
    background-position: center;

    @media (max-width: 1024px) {
      background-image: url("../../assets/images/visual-1280.png");
    }
    @media (max-width: 767px) {
      background-image: url("../../assets/images/visual-460.png");
    }

    @media (max-width: 460px) {
      background-image: url("../../assets/images/visual-375.png");
    }

    .image-side {
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 100%;
      width: 100%;
      min-height: 100vh;
    }
    .side-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 16px 44px;

      @media (max-width: 767px) {
        padding: 16px;
      }

      .site-log {
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        color: #ffffff;
      }
      .login-phone {
        a {
          text-decoration: none;
          display: flex;
          align-items: center;
        }
        img {
          margin-right: 8px;
        }
        span {
          font-size: 16px;
          line-height: 22px;
          color: #ffffff;
        }
      }
    }
    .side-center {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      .form-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-height: 85vh;
      }
    }
  }
}
