
.truck-modal-wrapper {
  width: 1056px;
  min-height: 660px;

  .truck-modal-title {
    color: #2A374E;
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
  }
}

.truck-modal-tabs-wrapper {
  margin: 24px 0 0;


  .MuiTabs-flexContainer{
    border-bottom: 2px solid #E6ECF3;
  }

  .MuiTabScrollButton-root.Mui-disabled {
    display: none;
  }

  .MuiTabs-indicator {
    background-color: #438AFE;
    height: 4px;
  }

  .MuiTab-wrapper {
    font-size: 16px;
    font-weight: bold;
    color: rgba(42, 55, 78, 0.50);
    text-transform: capitalize;
  }

  .MuiTab-textColorInherit.Mui-selected {
    span {
      color: #2A374E;
    }
  }
}

.truck-detail-title{
  color: #2A374E;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin: 24px 0;
}

.black-text{
  color: #2A374E;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.light-text{
  color: #9C9EA9;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 4px;
}

.truck-information-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 16px;
}

.information-title-value{
  width: 33%;
}

.information-title-value-big{
  width: 50%;
}

.equipment-details-wrapper{
  border-top: 1px solid #E6ECF3;
}

.destination-card-body{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.destination-card-wrapper{
  border-radius: 4px;
  background: #F7F9FC;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  width: 236px;
  margin-bottom: 4px;
  margin-right: 16px;
  p{
    color: #51566D;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-transform: capitalize;
    margin-left: 8px;
  }
}

//.write-review{
//  width: 100%;
//  height: 44px;
//  padding: 8px 16px;
//  margin-top: 24px;
//  border-radius: 4px;
//  background: rgba(67, 138, 254, 0.10);
//
//
//  .write-review-button{
//    display: flex;
//    align-items: center;
//    background: transparent;
//    border: none;
//    height: auto;
//
//    p{
//      color: #438AFE;
//      font-size: 18px;
//      font-style: normal;
//      font-weight: 600;
//      line-height: 28px;
//      margin-left: 15px;
//    }
//  }
//}

.overall-rating-wrapper{
  width: 100%;
  height: 100%;
  padding: 16px;
  background: var(--background-light, #F7F9FC);
}

.gray-text{
  color: #9C9EA9;
  font-size: 16px;
  line-height: 24px;
}

.gray-text-small{
  color: #9C9EA9;
  font-size: 14px;
  line-height: 20px;
}

.stars-wrapper{
  margin: 16px 0 8px 0;

  .MuiRating-label{
    color: #00C851;
    //margin-right: 12px;
  }
}



.comment-and-actions{
  margin-top: 24px;

  .comment-text-area{
    width: 100%;
    height: 128px;
    padding: 12px 16px;
    border-radius: 4px;
    border: 1px solid #9C9EA9;
    background: #FFF;
    resize: none;
    outline: none;
    margin-bottom: 16px;
  }
}

.create-button{
  button{
    width: 120px !important;
    height: 36px !important;
  }
}

.reviews-wrapper{
  margin-top: 24px;
  height: 190px;
  display: flex;
  align-items: flex-start;
  .stars-and-estimate{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 190px;
    width: 240px;
    min-width: 240px;
    p{
      color: #324557;
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 12px;
      line-height: 72px;
    }
    .MuiRating-root{
      color: #438AFE;
    }
    .MuiRating-label{
      color: #438AFE;
    }

  }

  //.reviews-and-ratings{
  //  display: flex;
  //  align-items: center;
  //  color: #51566D;
  //  font-size: 16px;
  //  font-weight: 600;
  //  line-height: 24px;
  //  margin-bottom: 13px;
  //  p{
  //    margin-right: 16px;
  //  }
  //}

  .star-count{
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;

    p{
      color: #324557;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-transform: capitalize;
      white-space: nowrap;
    }

    p:last-child{
      font-weight: 600;
      margin-left: 8px;
    }
    p:first-child{
      margin-right: 16px;
    }
  }

  .star-line{
    width: 100%;
    height: 8px;
    border-radius: 1px;
    background: #F5F7F9;

    div{
      height: 8px;
      border-radius: 1px;
      background: #438AFE;
    }
  }
}

//.show-reviews{
//  margin-top: 16px;
//  color: #438AFE;
//  font-size: 16px;
//  font-weight: 600;
//  line-height: 24px;
//  text-decoration-line: underline;
//  background: transparent;
//  border: none;
//}


//.review-card-wrapper{
//  border-radius: 4px;
//  background: #F7F9FC;
//  height: auto;
//  padding: 24px;
//  margin-top: 8px;
//
//  .title-and-stars{
//    display: flex;
//    justify-content: space-between;
//    align-items: flex-start ;
//    .MuiRating-root{
//      color: #438AFE;
//    }
//  }
//  .review-card-title{
//    color: #51566D;
//    font-size: 18px;
//    font-style: normal;
//    font-weight: 700;
//    line-height: 28px;
//  }
//  .review-count{
//    color: #51566D;
//    font-size: 16px;
//    font-style: normal;
//    font-weight: 600;
//    line-height: 24px;
//    margin-left: 16px;
//  }
//  .description{
//    margin: 16px 0;
//    color: #2A374E;
//    font-size: 16px;
//    font-weight: 400;
//    line-height: 24px;
//    text-transform: capitalize;
//  }
//  .date-row{
//    color: #51566D;
//    font-size: 14px;
//    font-weight: 400;
//    line-height: 20px;
//  }
//}




.action-buttons{
  button{
    background: transparent;
    border: none;
  }
}

.alerts-in-modal{
  margin-top: 24px;
}