
.truck-search-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  border-radius: 4px;
  background: #FFF;

  .add-search-wrapper {
    display: flex;
    align-items: center;
    width: 261px;
    padding: 0 12px 0 12px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #438AFE;

    input {
      border: none;
      background: transparent;
      outline: none;
      height: 100%;
      width: 100%;
    }

    .buttons-wrapper {
      display: flex;
      align-items: center;
    }

    .check-btn {
      margin: 0 10px;
    }

    button {
      background: transparent;
      border: none;
      cursor: pointer;
      width: 18px;
      height: 18px;
    }
  }

  .add-new-search {
    background: transparent;
    border: none;
    padding: 8px 16px;
    color: #00C851;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    align-items: center;

    img {
      margin-right: 8px;
    }
  }

  .truck-search-active-btn {
    border-radius: 4px;
    border: 1px solid #438AFE;
    background: #438AFE;
    padding: 6px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    margin-right: 8px;

    p {
      color: #FFF;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-transform: capitalize;
    }

    img {
      margin-left: 10px;
    }
  }

  .truck-search-btn {
    border-radius: 4px;
    border: 1px solid #E6ECF3;
    background: transparent;
    padding: 6px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    margin-right: 8px;

    p {
      color: #51566D;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-transform: capitalize;
    }

    img {
      margin-left: 10px;
    }
  }
}

.remove-btn{
  background: transparent;
  border: none;
  display: flex;
  width: 40px;

  img {
    transform: scale(0.4);
  }
}

.search-options-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
}

.search-inputs-wrapper {
  display: flex;
  align-items: center;

  .search-date-input {

    display: flex;
    margin-right: 8px;
    padding: 12px 16px;
    border-radius: 4px;
    border: 1px solid #9C9EA9;
    background: #FFF;
    width: 170px;
    min-width: 170px;
    height: 48px;
    outline: none;
    @media (min-width: 1919px) {
      width: 286px;
      min-width: 286px;
    }
  }

  .MuiFormControl-fullWidth {
    background: white;
    width: 170px;
    min-width: 170px;
    @media (min-width: 1919px) {
      width: 286px;
      min-width: 286px;
    }
  }

  .loadSimpleInput {
    margin-right: 8px;
  }


  .MuiAutocomplete-input {
    margin-top: -3px;
  }

  .MuiInputLabel-outlined {
    font-size: 14px;
  }

  .search-btn {
    border-radius: 8px;
    background: #438AFE;
    width: auto;
    padding: 12px;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    border: none;
    height: 48px;

    display: flex;
    align-items: center;

    @media (min-width: 1919px) {
      width: 131px;
      padding: 12px 24px;
    }

    p {
      margin-left: 8px;
    }
  }
}

.assigned-and-favorite-btn {
  padding: 14px 16px;
  border-radius: 4px;
  background: #E6ECF3;
  width: 90px;
  height: 48px;
  border: none;
  color: #2A374E;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
}

.assigned-and-favorite-active-btn {
  padding: 14px 16px;
  border-radius: 4px;
  background: #438AFE;
  width: 90px;
  height: 48px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
}

.assigned-Favorites-btn {
  button:first-child {
    margin-right: 18px;
  }

}

.table-review-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}

.alert-red {
  width: 24px;
  height: 24px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #D72525;

  p {
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
}

.alert-green {
  width: 24px;
  height: 24px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00C851;

  p {
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
}

.favorite-btn {
  background: transparent;
  border: none;
  padding: 0;
  width: 25px;
}

.more-actions-wrapper {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.10);
  width: 180px;
  height: 92px;

  button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 42px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    border: none;
    background: transparent;
    padding: 0 24px;

    img {
      margin-right: 10px;
    }
  }

  .action-edit-btn {
    color: #324557;
    border-bottom: 1px solid #E6ECF3;
  }

  .action-delete-btn {
    color: #D03325;
  }
}

.checkbox-tree {
  #simple-popper {
    z-index: 999;
  }

}

.destination-select-box {
  height: 48px;
  width: 170px;
  border-radius: 4px;
  border: 1px solid #9C9EA9;
  background: #FFF;
  padding: 12px 16px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 1919px) {
    width: 286px;
  }


  input{
    width: 100%;
    height: 46px;
    border: none;
    background: transparent;
    outline: none;
  }

  p {
    color: #9C9EA9;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

.destination-list {
  width: 170px;
  background: white;
  z-index: 99999;
  border-radius: 8px;
  max-height: 320px;
  overflow: auto;
  margin-top: 2px;
  padding: 8px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10);
  @media (min-width: 1919px) {
    width: 286px;
  }
}

.rct-text {
  margin-bottom: 12px;
}

.rct-node-icon {
  display: none;
}

.react-checkbox-tree label {
  display: flex;
  align-items: center;
}

.rct-checkbox * {
  width: 24px;
  height: 24px;
  color: #438AFE
}

.docket-number{

}