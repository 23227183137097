
.time-picker {
  width: 245px;
  border: 1px solid #51566DB3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  border-radius: 4px;
  padding: 0 14px;

  .time-picker-wrapper {
    display: flex;
    align-items: center;
  }

  select {
    border: none;
    width: 45px;
    outline: none;

    img{
      display: none;
    }
    img::selection {
      display: none;
    }

    svg {
      display: none;
    }
  }
}


.search-and-filer-box {

  p {
    font-size: 14px;
    font-weight: 600;
  }

  input {
    background: transparent;
    border: none;
    width: 100%;
    height: 32px;
    outline: none;
  }

  .search-input-title {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 20px;
    cursor: pointer;
  }

  .sort-btn-box {
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .small-desk {
    display: block;
    @media (min-width: 1919px) {
      display: none;
    }
  }

  .big-desk {
    display: none;
    @media (min-width: 1919px) {
      display: block;
    }
  }

  .passive-box {
    input {
      border-bottom: 1px solid #4B5C68;
    }

    p {
      color: #4B5C68;
    }

    //.sort-btn-box {
    img {
      filter: brightness(0) saturate(100%) invert(33%) sepia(27%) saturate(343%) hue-rotate(162deg) brightness(97%) contrast(90%);
    }

    //}
  }

  .active-box {
    input {
      border-bottom: 1px solid #347AF0;
    }

    p {
      color: #347AF0;
    }

    //.sort-btn-box {
    img {
      filter: invert(53%) sepia(63%) saturate(5642%) hue-rotate(204deg) brightness(97%) contrast(93%);
    }

    //}
  }
}
