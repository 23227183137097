

.mobile-app-page{
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: #2A374E;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;

  .mobile-app-page-title{
    color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 40px;
    @media (min-width: 1279px) {
      line-height: 84px;
      font-size: 56px;
    }

  }

  .mobile-box{
    background-image: url("../../assets/images/iPhone.png");
    background-repeat: no-repeat;
    width: 240px;
    height: 480px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;

    @media (min-width: 1279px) {
      width: 296px;
      height: 597px;
    }
    .logo{
      width: 65.172px;
      height: 91.241px;
      margin-bottom: 150px;
    }

    button{
      border: none;
      background: transparent;
      margin-top: 19px;
      width: 138.261px;
      height: 45.409px;
      margin-right: 15px;
      @media (min-width: 1279px) {
        width: 171.605px;
        height: 56.361px;
        margin-top: 24px;
      }
      img{
        width: 138.261px;
        height: 45.409px;
        @media (min-width: 1279px) {
          width: 171.605px;
          height: 56.361px;
        }
      }

    }


  }

}