@import "src/assets/styles/globalStyles.scss";

.authorities-terms-and-conditions-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  .authorities-terms-and-conditions-actions-wrapper {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: -48px;

    .authorities-terms-and-conditions-switcher-wrapper {
      width: 112px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;

      .authorities-terms-and-conditions-switcher {
        color: #000;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-transform: capitalize;
      }
    }
  }

  .authorities-rich-text-editor {
    width: 100%;

    .ButtonWrap__root___1EO_R {
      button {
        border: none;
        outline: none;
        background: none;
        margin: 0 4px;
        height: 28px;

        span {
          background-size: 24px
        }

      }

      button:hover {
        background: #00C851;
        border-radius: 8px;

        span {
          filter: brightness(0) invert(1);
        }
      }

      .IconButton__isActive___2Ey8p {
        background: #ddf3e6;
        border-radius: 8px;

        span {
          filter: invert(54%) sepia(49%) saturate(1349%) hue-rotate(101deg) brightness(94%) contrast(101%);
        }
      }
    }

    .DraftEditor-editorContainer {
      background: #F7F9FC !important;
      border-radius: 4px !important;
    }

    .RichTextEditor__root___2QXK- {
      margin-top: 30px !important;
      height: auto !important;
      background: #FFFFFF 0 0 no-repeat padding-box !important;
      border: 0.5px solid #E6ECF380 !important;
      border-radius: 6px !important;
    }

    .EditorToolbar__root___3_Aqz {
      height: 42px !important;
      border: none;
      background: #E6ECF3 0 0 no-repeat padding-box !important;
      border-radius: 6px 6px 0 0 !important;
      padding: 7px 16px !important;
      display: flex !important;
      align-items: baseline !important;
      margin: 0 !important;
    }

    .ButtonGroup__root___3lEAn {
      border-right: 0.5px solid #51566D80 !important;
      height: 26px !important;
    }

    .RichTextEditor__editor___1QqIU .public-DraftEditor-content, .RichTextEditor__editor___1QqIU .public-DraftEditorPlaceholder-root {
      padding: 16px !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: #545F7EB3 !important;
      height: 218px !important;
    }
  }
}

