@import "src/assets/styles/globalStyles";

.needed {
  font-size: 14px;
  font-weight: 600;
  color: $textPrimary;
}

.awaiting {
  font-size: 14px;
  font-weight: 600;
  color: $themeRed,
}

.sendTo {
  display: flex;
  width: 160px;

  img {
    margin-right: 8px;
  }
}

.blueButton {
  width: 141px;
  height: 32px;
  background: #438AFE 0% 0% no-repeat padding-box;
  box-shadow: 0 0 6px #438AFE4D;
  border-radius: 18px;
  color: white;
  font-size: 14px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.consign {
  width: 100px;
  height: 32px;
  background: #438AFE 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #438AFE4D;
  border-radius: 18px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  border: none;
  padding: 0;

  :hover {
    width: 100px;
    height: 32px;
    background: #3F51B5 0% 0% no-repeat padding-box;
    border-radius: 18px;
  }
}

.receivable-notes-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  padding: 6px 7px;
  gap: 10px;
  border-radius: 4px;
  background: #FFF;
  border: none;
  margin-right: 16px;
  @media (min-width: 1919px) {
    margin-right: 24px;
  }
}

.note-modal-wrapper{
  font-family: Open Sans, sans-serif;
  word-wrap: break-word;
  width: 1200px;
  height: 590px;
  strong{
    font-weight: 700;
  }
}

.receivables-note-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #E6ECF3;
  background: var(--White, #FFF);
  padding: 0 24px 24px 24px;
}

.search-note-box {
  display: flex;
  border-radius: 4px;
  border: 1px solid #E6ECF3;
  background: #FFF;
  width: 350px;
  height: 36px;
  margin-bottom: 12px;

  input {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    outline: none;
    padding: 0 16px;
  }

  button {
    background: transparent;
    cursor: pointer;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 4px 4px 0px;
    border-left: 1px solid #E6ECF3;
    border-right: none;
    border-top: none;
    border-bottom: none;
    background: var(--White, #FFF);
  }
}

.note-customer-load-wrapper {
  display: flex;
  flex-direction: column;
  gap: 9px;
  padding: 16px 0 0 0;
}

.note-customer-load-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;

  p {
    color: #2A374E;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  div {
    border-radius: 16px;
    display: flex;
    padding: 5px 8px;
    justify-content: center;
    align-items: center;
    color: #2A374E;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.note-subject-box {
  width: 100%;
  height: 100%;
  //border-top: 1px solid #A3B2BD;
  padding: 0 0 16px 0;
}


.note-text-editor-wrapper{
  .ButtonWrap__root___1EO_R {
    button {
      border: none;
      outline: none;
      background: none;
      margin: 0 4px;
      height: 28px;

      span {
        background-size: 24px;
        filter: invert(33%) sepia(15%) saturate(633%) hue-rotate(192deg) brightness(98%) contrast(93%);
      }
    }




    button:hover {
      background: #E6ECF3;
      border-radius: 8px;

      span {
        filter: invert(33%) sepia(15%) saturate(633%) hue-rotate(192deg) brightness(98%) contrast(93%);
      }
    }

    .IconButton__isActive___2Ey8p {
      background: #E6ECF3;
      border-radius: 8px;
      span {
        filter: invert(33%) sepia(15%) saturate(633%) hue-rotate(192deg) brightness(98%) contrast(93%);
      }
    }
  }

  .DraftEditor-editorContainer {
    border: none !important;
    border-radius: 0 !important;
  }

  .RichTextEditor__root___2QXK- {
    margin-top: 30px !important;
    height: auto !important;
    background: #FFFFFF 0 0 no-repeat padding-box !important;
    border: 0.5px solid #E6ECF380 !important;
    border-radius: 6px !important;
  }

  .EditorToolbar__root___3_Aqz {
    height: 42px !important;
    border: none;
    background: #E6ECF380 0 0 no-repeat padding-box !important;
    border-radius: 6px 6px 0 0 !important;
    padding: 7px 4px !important;
    display: flex !important;
    align-items: baseline !important;
    margin: 0 !important;
  }

  .ButtonGroup__root___3lEAn {
    border-right: 0.5px solid #51566D80 !important;
    height: 26px !important;
  }

  .RichTextEditor__editor___1QqIU .public-DraftEditor-content, .RichTextEditor__editor___1QqIU .public-DraftEditorPlaceholder-root {
    padding: 16px !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #545F7EB3 !important;
    height: 210px !important;
    width: 100% !important;
  }
}