
.create-modal-wrapper {
  width: 440px;
  @media (min-width: 1919px) {
    width: 520px;
  }

  .create-template-title {
    color: #2A374E;
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
  }

  .create-template-subtitle {
    margin: 16px 0 24px 0;
    color: #51566D;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .max-characters {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: #9C9EA9;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-top: -20px;
  }
}

.template-cards-body{
  height: 70vh;
  overflow: scroll;
  margin-top: 32px;
}
.template-card-wrapper{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-radius: 8px;
  background:  #FFF;
  padding: 14px 24px;
  height: 80px;
  margin-bottom: 12px;
}

.template-image-and-text{
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  img{

    width: 32px;
    height: 32px;
  }

  .template-title{
    margin-bottom: 8px;
    .text-style {
      color: #2A374E;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }

  .template-description{
    color:  #7B7B7B;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.action-buttons-wrapper{
  display: flex;
  align-items: center;
  button{
    background: transparent;
    border: none;
  }
  .remove-template-btn{
    background: transparent;
    border: none;
  }
}

.template-card-section{
  margin-bottom: 24px;
}

.template-load-sections{
  width: 100%;
  height: 100%;
  padding: 32px 24px;
  border-radius: 8px;
  background:  #FFF;
}