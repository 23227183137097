
.agent-profile-page {
  min-height: 90vh;
  background: #FFF;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 6px 0px rgba(138, 138, 138, 0.16);
}

.line-fragment-box {
  width: 100%;
  margin-top: 16px;
  @media (min-width: 1920px) {
    margin-top: 24px;
  }
}

.my-information-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 24px 24px;

  .my-information-box {
    border-radius: 4px;
    background: #F2F5F9;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 30%;

    span {
      color: #555768;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      white-space: nowrap;
    }

    p {
      width: 100%;
      color: #000624;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.information-box-title {
  margin: 24px 0 16px;
  color: #2A374E;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}