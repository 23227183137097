

.add-alert-btn {
  position: absolute;
  right: 16px;
  margin-top: -70px;
}

.alerts-head-wrapper {
  width: 100%;
  height: 50px;
  background: #E6ECF3;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .alerts-head-item {
    display: flex;
    align-items: center;
    padding: 0 32px;
    color: #51566D;
    font-size: 14px;
    font-weight: 600;

    button {
      background: transparent;
      border: none;
      margin-left: 6px;
      margin-bottom: -4px;
    }
  }
}

.alerts-body {
  max-height: 220px;
  overflow: auto;
  height: auto;
}


.alerts-body-wrapper {
  width: 100%;
  height: 50px;
  background: #F7F9FC;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .alerts-body-item {
    display: flex;
    align-items: center;
    padding: 0 32px;
    color: #51566D;
    font-size: 14px;
    font-weight: 400;

    button {
      background: transparent;
      border: none;
    }
  }
}

.alert-inputs{
  margin-top: 40px;
  width: 500px;

}