@import "src/assets/styles/globalStyles";

.payments-accordion-wrapper {
  margin-top: 24px;
  padding: 0 0 0 50px;
  max-height: 70vh;
  overflow: auto;

  .payments-table{
    margin-top: 24px;
  }

  .payment-load-title {
    font: normal normal bold 18px/24px Open Sans;
  }

  .payments-table-head{
    width: 47%;
    padding: 13px 32px;
    font: normal normal bold 16px/22px Open Sans;
    color: #51566D;
  }

  .head-item{
    font: normal normal normal 16px/22px Open Sans;
  }

  .payments-table-body{
    display: flex;
    background: #E6ECF380 0% 0% no-repeat padding-box;
    border-radius: 4px 0 0 4px;
    margin-bottom: 4px;
    font: normal normal normal 16px/22px Open Sans;
    color: #51566D;
  }

  .payments-table-body-item{
    width: 47%;
    padding: 13px 32px;
  }

  .details-button {
    background: transparent;
    border: none;
    color: #438AFA;
    font: normal normal normal 14px/19px Open Sans;
  }

}



.add-equipment-button{
  position: absolute;
  right: 0;
  margin-top: -50px
}

.small-switch-text {
  font-size: 14px;
  color: $textSecondary;
  margin-right: 8px;
  width: auto
}

.carrier-inputs-wrapper{
  display: flex;
  flex-wrap: wrap;
  gap:0 12px;
  justify-content: space-between;
  margin-top: 12px;

  & .input-wrapper{
    width: 32%;
  }
}