
.modal-wrapper-style {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 8px;
  position: relative;
  outline: none;
}

.modal-header-style {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 8px 0;
}

.modal-body-style {
  height: auto;
  max-height: 90vh;
  overflow: auto;
  width: auto;
  padding: 32px;

  @media (min-width: 1919px) {
    padding: 40px;
  }
}
.modal-body-style-no-padding {
  height: auto;
  max-height: 90vh;
  overflow: auto;
  width: auto;
}


.customer-information-wrapper {
  @media (min-width: 1919px) {
    height: 650px;
  }
  width: 709px;
  height: 450px;
}

.customer-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #2A374E;
  margin-bottom: 32px;
}

.modal-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #2A374E;
  margin-bottom: 16px;
}

.modal-subtitle{
  color: #51566D;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.basic-info {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #438AFE;
  margin-bottom: 16px;
}

.basic-info-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
}

.type-and-text {
  width: 32%;
  display: flex;
  flex-direction: column;

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #9C9EA9;
    margin-bottom: 4px;
  }

  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2A374E;
  }
}

.bottom-line-style {
  height: 1px;
  border-bottom: 1px solid #E6ECF3;
  border-top: none;
  margin-bottom: 20px;
}


.payment-table-modal-wrapper {
  @media (min-width: 1919px) {
    width: 1419px;
    height: 660px;
  }

  width: 899px;
  height: 500px;


  .title {
    font: normal normal bold 32px/43px Open Sans;
    color: #2A374E;
  }

  .close-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 8px 0;
  }

  .grand-total {
    margin-top: 16px;
    width: auto;
    height: 40px;
    background: #E6ECF3 0% 0% no-repeat padding-box;
    border-radius: 8px;
    padding: 9px 16px;
    display: flex;
    align-items: center;
  }
}


// Delete Modal

.delete-modal-wrapper {
  width: 486px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .delete-modal-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 43px;
    color: #2A374E;
  }

  .delete-modal-text {
    font-size: 16px;
    line-height: 24px;
    color: #51566D;
    margin: 16px 0 20px 0;
    @media (min-width: 1919px) {
      margin: 16px 0 40px 0
    }
  }

  .delete-info-text {
    color: #D72525;
    font-size: 18px;
  }

  .close-or-delete-btn {
    text-transform: capitalize;
    width: 100%;
    height: 48px;
    background-color: #D72525;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    color: white;
  }

  .modal-close-btn {
    text-transform: capitalize;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: none;
    background: #E6ECF380;
    margin-left: 16px;
    font-size: 16px;
    font-weight: 600;
    color: #51566D;
  }
}

// End


// Note Modal

.note-modal{
  width: 500px;
}

// End





.write-review{
  width: 100%;
  height: 44px;
  padding: 8px 16px;
  margin-top: 24px;
  border-radius: 4px;
  background: rgba(67, 138, 254, 0.10);


  .write-review-button{
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    height: auto;

    p{
      color: #438AFE;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      margin-left: 15px;
    }
  }
}

.overall-rating-wrapper{
  width: 100%;
  height: 100%;
  padding: 16px;
  background: var(--background-light, #F7F9FC);
}

.gray-text{
  color: #9C9EA9;
  font-size: 16px;
  line-height: 24px;
}

.gray-text-small{
  color: #9C9EA9;
  font-size: 14px;
  line-height: 20px;
}

.stars-wrapper{
  margin: 16px 0 8px 0;

  .MuiRating-label{
    color: #00C851;
    //margin-right: 12px;
  }
}


.reviews-wrapper{
  margin-top: 24px;
  height: 190px;
  display: flex;
  align-items: flex-start;
  .stars-and-estimate{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 190px;
    width: 240px;
    min-width: 240px;
    p{
      color: #324557;
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 12px;
      line-height: 72px;
    }
    .MuiRating-root{
      color: #438AFE;
    }
    .MuiRating-label{
      color: #438AFE;
    }

  }

  .reviews-and-ratings{
    display: flex;
    align-items: center;
    color: #51566D;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 13px;
    p{
      margin-right: 16px;
    }
  }

  .star-count{
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;

    p{
      color: #324557;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-transform: capitalize;
      white-space: nowrap;
    }

    p:last-child{
      font-weight: 600;
      margin-left: 8px;
    }
    p:first-child{
      margin-right: 16px;
    }
  }

  .star-line{
    width: 100%;
    height: 8px;
    border-radius: 1px;
    background: #F5F7F9;

    div{
      height: 8px;
      border-radius: 1px;
      background: #438AFE;
    }
  }
}

.show-reviews{
  margin-top: 16px;
  color: #438AFE;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-decoration-line: underline;
  background: transparent;
  border: none;
}


.review-card-wrapper{
  border-radius: 4px;
  background: #F7F9FC;
  height: auto;
  padding: 24px;
  margin-bottom: 8px;

  .title-and-stars{
    display: flex;
    justify-content: space-between;
    align-items: flex-start ;
    .MuiRating-root{
      color: #438AFE;
    }
  }
  .review-card-title{
    color: #51566D;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
  .review-count{
    color: #51566D;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-left: 16px;
  }
  .description{
    margin: 16px 0;
    color: #2A374E;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-transform: capitalize;
  }
  .date-row{
    color: #51566D;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .delete-btn{
    background: transparent;
    border: none;
    margin-left: 12px;
  }
}

.reviews-body-wrapper{
  max-height: 500px;
  height: auto;
  overflow: auto;
  margin-top: 8px;
}

.edit-btn{
  background: transparent;
  border: none;
  margin-left: 16px;
}

.edit-review-area{
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 16px 0;
  resize: none;
  outline: none;
  min-height: 50px;
  padding: 16px;
}


.load-modal-body-style {
  height: auto;
  max-height: 90vh;
  overflow: auto;
  width: auto;
  border-radius: 8px;
}