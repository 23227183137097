


.add-load-btn{
  width: 180px;
  height: 36px;
  border-radius:32px;
  background: #00C851;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 6px 0px rgba(0, 200, 81, 0.30);

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  p{
    color: var(--text-white, #FFF);
  }

  button{
    display: flex;
    align-items: center;
    border: none;
    background: transparent;

  }

  .plus{
    font-size: 27px;
    margin-right: 8px;
    margin-bottom: 2px;
    margin-left: 15px;
  }

  .add-text{
    color: var(--text-white, #FFF);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-right: 16px;
  }
}


.load-templates-popper{
  width: 400px;
  min-height: 280px;
  max-height: 450px;
  padding: 16px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0 8px 16px 0 rgba(165, 163, 163, 0.10);
}

.choose-title{
  color: var(--text-secondary, #2A374E);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.search-input-wrapper{
  width: 100%;
  height: 36px;
  border: 1px solid var(--background-primary, #E6ECF3);
  background: #FFF;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin: 16px 0;

  input{
    border: none;
    background: transparent;
    outline: none;
    width: 100%;
    padding: 0 12px;
  }
}

.search-input-image{
  width: 48px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  border-left: 1px solid #E6ECF3;
}

.templates-row{
  width: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  height: 36px;
  cursor: pointer;
  p{
    margin-left: 10px;
    color: #2A374E;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
  }

}

.templates-row:hover{
  border-radius: 4px;
  background: #F7F9FC;
}

.templates-row-body{
  height: 300px;
  overflow: auto;
}

.load-summary-wrapper{
  padding: 0 0 24px 0 ;
  margin-bottom: 32px;
}

.status-wrapper{
  padding: 8px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border-radius: 4px;
  text-wrap: nowrap;
}

.load-details-wrapper{
  border-bottom: 1px solid #E6ECF3;
  padding-bottom: 14px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .summary-row-wrapper{
    width: 25%;
  }

  .load-detail-title{
    color: #9C9EA9;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .load-detail-subtitle{
    color:  #2A374E;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
}

.load-actions-box{
  width: 100%;
  padding: 20px 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: #E6ECF3;

  .action-switcher-box{
    display: flex;
    align-items: center;
  }
}

.cancel-load-btn-box {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 24px;
  .cancel-btn {
    width: 206px;
    height: 48px;
    background: #D72525;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    border: none;
  }
}
