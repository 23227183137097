

.equipment-details-page{
  width: 100%;
  height: 100%;
  min-height: 80vh;
  background: #FFF;
  padding: 24px;
}

.zone-space-between{
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  margin-top: 24px;
}

.zone-card-shadow{
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0 0 6px 0 rgba(138, 138, 138, 0.16);
  padding: 16px;
  width: 49%;
  height: 100%;
}

.zone-card-wrapper{
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.zone-card{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-left: 16px;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.zone-items{
  margin-bottom: 8px;
  height: 48px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
}

.zone-item-text-icon{
  display: flex;
  align-items: center;

  p{
    color: #51566D;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-transform: capitalize;
  }
  img{
    margin-right: 8px;
  }
}

.return-home-base-selector{
  display: flex;
  justify-content: flex-end;

  div{
    display: flex;
    align-items: center;
  }

  .home-base-selector-title{
    color: #2A374E;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-right: 8px;
  }
}

.date-input-wrapper{
  width: 100%;
  margin-right: 16px;
}