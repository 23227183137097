.system-page {
  .title-and-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    //margin-top: 40px;
  }

  .ButtonWrap__root___1EO_R {
    button {
      border: none;
      outline: none;
      background: none;
      margin: 0 4px;
      height: 28px;
      span {
        background-size: 24px
      }
    }

    button:hover {
      background: #00C851;
      border-radius: 8px;

      span {
        filter: brightness(0) invert(1);
      }
    }

    .IconButton__isActive___2Ey8p {
      background: #ddf3e6;
      border-radius: 8px;
      span {
        filter: invert(54%) sepia(49%) saturate(1349%) hue-rotate(101deg) brightness(94%) contrast(101%);
      }
    }
  }

  .DraftEditor-editorContainer {
    border: 1px solid #2A374E80 !important;
    border-radius: 6px !important;
  }

  .RichTextEditor__root___2QXK- {
    margin-top: 24px !important;
    height: auto !important;
    background: #FFFFFF 0 0 no-repeat padding-box !important;
    border: 0.5px solid #E6ECF380 !important;
    border-radius: 6px !important;
  }

  .EditorToolbar__root___3_Aqz {
    height: 42px !important;
    border: none;
    background: #E6ECF380 0 0 no-repeat padding-box !important;
    border-radius: 6px 6px 0 0 !important;
    padding: 7px 16px !important;
    display: flex !important;
    align-items: baseline !important;
    margin: 0 !important;
  }

  .ButtonGroup__root___3lEAn {
    border-right: 0.5px solid #51566D80 !important;
    height: 26px !important;
  }

  .RichTextEditor__editor___1QqIU .public-DraftEditor-content, .RichTextEditor__editor___1QqIU .public-DraftEditorPlaceholder-root {
    padding: 32px !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #545F7EB3 !important;
    height: 436px !important;
  }
}


.rmis-updates-wrapper{
  display: flex;
  align-items: center;
  margin-top: 8px;

  .rmis-tab-wrapper{
    width: 377px;
    height: 72px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(138, 138, 138, 0.16);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
  }

  .rmis-tab-image-text{
    display: flex;
    align-items: center;

    p{
      margin-left: 10px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }
}