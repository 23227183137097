// Landing Page Style

// Landing Style.

@import "src/assets/styles/globalStyles";

.landing-page {
  font-family: "Mukta", sans-serif !important ;
  background: white;
  overflow-x: hidden;
  width: 100%;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  a,
  div {
    font-family: "Mukta", sans-serif !important ;
  }

  .MuiList-root {
    .dark-link {
      .css-10hburv-MuiTypography-root {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .green-link {
      .css-8je8zh-MuiTouchRipple-root {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $main;
      }
    }
  }

  .side-menu {
    .dark-link {
      .css-10hburv-MuiTypography-root {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .green-link {
      .css-8je8zh-MuiTouchRipple-root {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $main;
      }
    }
  }

  .dark-link {
    text-transform: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .full-img {
    width: 100%;
  }

  .MuiButton-contained:hover {
    background: #438afe;
    box-shadow: none;
  }

  .blue-btn {
    background: #438afe;
    border-radius: 8px;
    color: white;
    align-items: center;
    padding: 12px 52px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    @media (max-width: 1200px) {
      padding: 10px 44px;
    }

    :hover {
      background: #438afe;
      box-shadow: none;
    }
  }

  .global-container {
    margin-top: 35px;

    @media (max-width: 1024px) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media (max-width: 1024px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .landing-header {
    padding: 20px;
    background-repeat: no-repeat;
    background-position: 100%;

    .header-container {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .header-box {
      display: flex;
      justify-content: space-between;

      .box-logo {
        padding-top: 10px;
      }

      .dark-link {
        margin-right: 24px;
      }

      .header-links {
        a {
          text-decoration: none !important;
        }
      }

      .burger-link {
        display: none;
        @media only screen and (max-width: 1023px) {
          display: block;
        }
      }
    }

    .aside-header {
      margin-top: 200px;
      margin-bottom: 178px;
      width: 50%;

      @media (max-width: 1440px) {
        width: 100%;
        margin-top: 100px;
        margin-bottom: 100px;
      }

      .header-title {
        font-size: 48px;
        line-height: 72px;
        font-weight: 600;
        display: flex;
        flex-direction: column;
      }

      .subtitle-box {
        margin-top: 30px;

        .header-subtitle {
          font-style: normal;
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
        }
      }

      .header-get {
        margin-top: 40px;
        max-width: 333px;
      }
    }
  }

  .section-header {
    display: none;
    .header-title {
      font-size: 48px;
      line-height: 72px;
      font-weight: 600;
      .title-loads {
        margin-left: 5px;
      }
    }

    .subtitle-box {
      margin-top: 20px;

      .header-subtitle {
        font-style: normal;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
      }
    }

    .header-get {
      margin-top: 18px;
      width: 263px;
    }
  }

  .section-banner {
    margin-top: 90px;
    margin-bottom: 90px;

    .card-banner {
      padding: 16px;
      border-radius: 8px;
      .card-title {
        padding: 0 16px;
      }
    }
  }

  .section-revolutionize {
    padding-top: 80px;

    .order-content {
      margin-top: 150px;

      .title-we {
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
        color: #2a374e;
      }
    }

    .box-text {
      margin-top: 18px;

      .type-text {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #2a374e;
      }
    }

    .btn-box {
      margin-top: 24px;
    }

    @media (max-width: 1024px) {
      margin-top: 80px;
      .order-content {
        margin-top: 82px;

        .box-text {
          max-width: 500px;
        }
      }
      .order-img {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 580px;
          max-height: 580px;
        }
      }
    }
    @media (max-width: 767px) {
      padding-top: 20px;
      margin-top: 0;
      .order-content {
        .title-we {
          font-weight: 600;
          font-size: 32px;
          line-height: 48px;
        }
      }
    }
  }

  .section-efficiency {
    padding-top: 120px;

    .section-title {
      display: flex;
      justify-content: center;

      .title {
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
        text-align: center;
      }
    }

    .features-card {
      margin-top: 56px;

      .spacing {
        & .MuiGrid-item {
          padding: 24px;
          @media (max-width: 767px) {
            padding: 8px;
          }
        }
      }

      .card-features {
        background: #ffffff;
        border: 1px solid #f1f1f1;
        border-radius: 16px;
        padding: 37px 24px;
        display: flex;
        flex-direction: column;
        height: 315px;

        .card-description,
        .card-title,
        .img-box {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .card-title {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: #2a374e;
        }

        .card-title,
        .card-description {
          margin-top: 18px;
        }

        .features-parapgraphy {
          text-align: center;
          align-self: stretch;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    @media (max-width: 1024px) {
      .order-img {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 580px;
          max-height: 580px;
        }
      }
      .order-content {
        .box-text {
          max-width: 500px;
        }
      }
    }
    @media (max-width: 767px) {
      padding-top: 80px;
      .section-title {
        .title {
          font-weight: 600;
          font-size: 32px;
          line-height: 48px;
        }
      }
    }
  }

  .section-trusted {
    padding-top: 160px;

    .order-content {
      margin-top: 20px;

      .title-we {
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
        color: #2a374e;
      }

      .box-text {
        margin-top: 24px;

        .type-text {
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          color: #2a374e;
        }
      }
    }

    @media (max-width: 767px) {
      padding-top: 70px;
      .order-content {
        .title-we {
          font-weight: 600;
          font-size: 32px;
          line-height: 48px;
        }
      }
    }
  }

  .section-call {
    margin-top: 160px;
    background: #2a374e;

    .action-container {
      padding: 90px;
    }

    .order-content {
      margin-top: 135px;

      .how-step {
        margin-bottom: 24px;
      }

      .title {
        color: #ffffff;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }

      .account-text {
        margin-top: 12px;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
    }

    .order-img {
    }
    @media (max-width: 767px) {
      margin-top: 80px;

      .order-content {
        order: 2;
        margin-top: 0;
      }
      .order-img {
        order: 1;
      }
    }
  }

  .section-benefits {
    padding-top: 120px;
    .order-content {
      margin-top: 160px;
      .title-we {
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
        color: #2a374e;
      }
      .box-text {
        margin-top: 24px;
        .type-text {
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          color: #2a374e;
        }
      }
    }

    @media (max-width: 767px) {
      padding-top: 100px;
      .order-content {
        margin-top: 20px;
        .title-we {
          font-weight: 600;
          font-size: 32px;
          line-height: 48px;
        }
      }
    }
  }

  .section-transport {
    margin-top: 160px;
    margin-bottom: 120px;
    .order-content {
      margin-top: 120px;
      .title-we {
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
        color: #2a374e;
      }
      .box-text {
        margin-top: 24px;
        .type-text {
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          color: #2a374e;
        }
      }
    }

    @media (max-width: 1024px) {
      .order-content {
        order: 2;
      }
      .order-img {
        order: 1;
      }
    }
    @media (max-width: 767px) {
      margin-top: 60px;
      margin-bottom: 60px;
      .order-content {
        margin-top: 20px;
        .title-we {
          font-weight: 600;
          font-size: 32px;
          line-height: 48px;
        }
      }
    }
  }
}

@media (max-width: 12000px) {
  .order-content {
    margin-top: 82px;
    .title-we,
    .box-text {
      max-width: 500px;
    }
  }
  .order-img {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 580px;
      max-height: 580px;
    }
  }
}

.header-section {
  padding: 16px 100px;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(165, 163, 163, 0.1);
  position: fixed;
  width: 100%;
  z-index: 2000;
  top: 0;
  @media (max-width: 1200px) {
    padding: 16px 40px;
  }
  @media (max-width: 768px) {
    padding: 16px 0;
  }

  .header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .burger-link {
      display: none;
    }
    .dark-link {
      margin-right: 24px;
    }
    .social-box {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  .header-links {
    display: flex;
    align-items: center;

    a {
      text-decoration: none !important;
    }

    .not-hover:hover {
      background-color: inherit;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }
}

//   Footer
.footer {
  background: #2a374e;
  padding: 24px 100px;
  color: white;

  .footer-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .footer-content {
      display: flex;
      align-items: center;
      flex-direction: row;
      @media (max-width: 767px) {
        flex-direction: column;

        .footer-title {
          margin-bottom: 16px;
        }
      }
      @media (max-width: 767px) {
        .footer-info {
          text-align: start;
          width: 100%;
        }
      }
    }

    .privacy-link,
    .terms-link {
      margin-right: 15px;
    }

    .privacy-text {
      color: white;
      text-decoration: underline;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }

    .footer-img {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    .social-box {
      margin-left: 30px;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .contact-btn {
      background: inherit;
      border: none;
    }

    @media (max-width: 767px) {
      align-items: flex-start !important;
      text-align: start;
    }
  }

  @media (max-width: 1024px) {
    padding: 24px 40px;
    .footer-section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .footer-img {
        margin-bottom: 24px;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 24px 0;
  }
}
