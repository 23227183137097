@import "src/assets/styles/globalStyles";


.text-tool-tip-wrapper {
  width: 100%;

  .main-tooltip-text {
    width: 100%;

    overflow: hidden;
    color: $primary;
    text-overflow: ellipsis;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-transform: capitalize;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    overflow-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    text-decoration: none;
  }

  .text-tooltip-phone {
    overflow: hidden;
    color: $primary;
    text-overflow: ellipsis;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-transform: capitalize;
    text-decoration: none;
  }
}

.text-tool-tip-box {
  padding: 6px 16px !important;
  border-radius: 4px !important;
  background: $textSecondary !important;

  color: #FFF;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  text-transform: capitalize !important;
}