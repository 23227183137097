@import "src/assets/styles/globalStyles";

.payroll-page {
  .payroll-title {
    font: normal normal bold 24px/33px Open Sans;
    color: $textSecondary;
  }

  .info-text {
    font: normal normal normal 16px/22px Open Sans;

    span {
      color: $main;
    }
  }

  .summary-head {
    display: flex;
    justify-content: space-between;
    background: $primaryGray;
    border-radius: 8px 8px 0 0;
    margin-top: 32px;

    div {
      width: 14%;
      padding: 16px;
      font: normal normal bold 14px/19px Open Sans;
      color: $textSecondary;
      display: flex;
      align-items: center;
    }
  }

  .summary-body {
    display: flex;
    justify-content: space-between;
    background: white;
    margin-bottom: 32px;

    p {
      width: 14%;
      padding: 16px;
      font: normal normal 600 14px/19px Open Sans;
      color: $textPrimary;
    }
  }

  .select-filter-wrapper{
    background: white;
    border: 1px solid #848898;
    border-radius: 4px;
    padding: 0 16px;
    width: 228px;
    height: 36px;
    display: flex;
    align-items: center;
    margin-right: 16px;
  }

  .select-filter{
    width: 200px;
    height: 32px;
    border: none;
    outline: none;
    font: normal normal normal 14px/19px Open Sans;
    color: $primary;
  }

  .filter-date{
    background: white;
    border: 1px solid #848898;
    border-radius: 4px;
    padding: 0 16px;
    height: 36px;
  }
}

.buttons-tab-wrapper{
  width: auto;
  border-radius: 32px;
  background: #FFF;
  box-shadow: 0 0 6px 0 rgba(138, 138, 138, 0.16);
  padding: 2px;
  height: 36px;
  display: flex;
  align-items: center;

  button{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    border-radius: 32px;
    border: none;
    width: 110px;
    padding: 0;
  }

  .active-btn{
    background: #438AFE;
    color: #FFF;
  }

  .passive-btn{
    background: transparent;
    color: #51566D;
  }
}

.load-payroll-wrapper{
  margin-top: 24px;
  padding-bottom: 100px;

  .MuiAccordion-root{
    margin-bottom: 0!important;
  }

  .MuiAccordionSummary-content{
    margin: 0;
  }
  .MuiAccordionSummary-content.Mui-expanded{
    margin: 0;
  }
  .MuiAccordion-root.Mui-expanded{
    margin: 0;
  }
  .MuiAccordionSummary-root.Mui-expanded{
    min-height: 50px;
  }
  .MuiAccordionDetails-root{
    padding: 0 16px!important;
    background: #E6ECF3;
  }

  .loaf-payroll-table{
    width: 100%;
   .MuiTableCell-stickyHeader{
     background: white!important;
   }
  }
}


.accordion-table-head-wrapper{
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px 8px 0 0;
  background: #ECF1FA;

  .accordion-table-head{
    padding: 16px 10px ;
  }
}

.accordion-table-body-scroll{
  max-height: 65vh;
  overflow: auto;
}

.accordion-table-body-wrapper{
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #E6ECF3;
  background: #FFF;


  .accordion-table-body{
    padding: 16px 10px;
    color: #51566D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: capitalize;

  }
}