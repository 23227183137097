@import "src/assets/styles/globalStyles";

.info-and-image {
  display: flex;
  align-items: center;

  p {
    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
    color: $textSecondary;
  }

  img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    @media (min-width: 1920px) {
      margin-right: 8px;
    }
  }
}

.pending-icon-color{
  filter: brightness(0) saturate(100%) invert(81%) sepia(51%) saturate(2653%) hue-rotate(331deg) brightness(98%) contrast(107%);
}

.inTransit-icon-color{
  filter: brightness(0) saturate(100%) invert(32%) sepia(14%) saturate(4973%) hue-rotate(205deg) brightness(93%) contrast(94%);
}

.processing-icon-color{
  filter: brightness(0) saturate(100%) invert(55%) sepia(87%) saturate(1053%) hue-rotate(142deg) brightness(90%) contrast(101%);
}

.rejected-icon-color{
  filter: brightness(0) saturate(100%) invert(26%) sepia(80%) saturate(3244%) hue-rotate(347deg) brightness(88%) contrast(97%);
}

.completed-icon-color{
  filter: invert(64%) sepia(42%) saturate(7189%) hue-rotate(112deg) brightness(103%) contrast(101%);
}




.hyper-link{
  background: transparent;
  padding: 0;
  border: none;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $primary;
}

.call-to-action{
  background: transparent;
  padding: 0;
  border: none;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #438AFE;
}